<template>
    <div>
        <my-image src="https://file4.mogody.com/avatars/history_bg.jpg" />
        <van-list
            v-model="listLoading"
            :finished="listFinished"
            finished-text="没有更多了"
            :error.sync="error"
            @load="onLoad"
            error-text="加载,点击重新加载"
        >
            <list-item v-for="(medium, i) in mediums.data" :key="i" :medium="medium"></list-item>
        </van-list>
    </div>
</template>

<script>
import { getHistoryList } from '@/api/user'
import ListItem from './widgets/ListItem'
import MyImage from '@/components/MyImage'

export default {
    name: 'UserHistory',
    components: { ListItem, MyImage },
    data () {
        return {
            listLoading: false,
            listFinished: false,
            error: false,
            mediums: {
                meta: {
                    current_page: 0,
                    last_page: 0,
                },
                data: []
            }
        }
    },
    methods: {
        onLoad () {
            getHistoryList({
                page: this.mediums.meta.current_page + 1
            }).then(res => {
                res.data.unshift(...this.mediums.data)
                this.mediums = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    }
};
</script>

<style lang="less">

</style>
